import * as React from "react";
import styled from "styled-components";
import { Stack, Text } from "./components";

const Container = styled(Stack)`
  text-decoration: none;
  a {
    color: inherit;
    text-decoration: none;
    transition: color 250ms ease;
  }
  a:hover {
    color: black;
  }
`;

const ContactDetails = () => (
  <Container gap={8} color="secondary">
    <Text
      block
      as="a"
      href="mailto:studio@danielbouquet.com"
      hover
      textStyle="more"
    >
      studio@danielbouquet.com
    </Text>

    <Text>FILM / REPRESENTED BY</Text>

    <Stack gap={2}>
      <Text mb={5}>MELTING POT AGENCY PARIS</Text>
      <Text block as="a" href="tel:0033155331644" hover textStyle="more">
        +33(0)155331644
      </Text>
      <Text
        block
        as="a"
        href="mailto:benoit@meltingpotagency.com"
        hover
        textStyle="more"
      >
        Benoit Renaud / benoit@meltingpotagency.com
      </Text>
      <Text
        block
        as="a"
        href="mailto:vanessa@meltingpotagency.com"
        hover
        textStyle="more"
      >
        Vanessa Guissin / vanessa@meltingpotagency.com
      </Text>
      <Text mb={5} mt={6}>
        US / CREATIVE ARTISTS AGENCY
      </Text>
      <Text block as="a" href="tel:0014242882000" hover textStyle="more">
        +1(0)4242882000
      </Text>
      <Text
        block
        as="a"
        href="mailto:erin.randol@caa.com"
        hover
        textStyle="more"
      >
        Commercials / Erin Randol / erin.randol@caa.com
      </Text>
      <Text
        block
        as="a"
        href="mailto:ann.murphy@caa.com"
        hover
        textStyle="more"
      >
        Features / Ann Murphy / ann.murphy@caa.com
      </Text>
    </Stack>

    <Text mb={6}>
      <a
        href="https://www.instagram.com/daniellucasbouquet"
        target="_blank"
        rel="noopener noreferrer"
      >
        INSTAGRAM
      </a>{" "}
      /{" "}
      <a
        href="https://vimeo.com/danielbouquet"
        target="_blank"
        rel="noreferrer"
      >
        VIMEO
      </a>
    </Text>
  </Container>
);

export default ContactDetails;
